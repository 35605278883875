// filter-mobile
.filter-links-bottom {
  position: fixed;
  left: 0;
  bottom: 0px;
  box-shadow: 0 13px 9px 12px rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: all 0.2s;
  z-index: 11;
  background: #fff;

  .filter-links {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    width: 100%;
    padding: 15px 10px;

    .filter-list {
      border-right: 1px solid #d1d1d1;
      padding: 0 25px;
      width: 33%;
      text-align: center;
      position: relative;

      &:last-child {
        border-right: 0px;
      }

      .filter_btn {
        color: #333333;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        font-family: 'pangramregular';
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 2px;
        }
      }
      .filter-call {
        position: relative;
        .toptool {
          color: #ffffff;
          background: #e27a34;
          font-size: 0.625rem;
          line-height: 11px;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          vertical-align: middle;
          position: absolute;
          left: 5px;
          top: -5px;
          text-align: center;
        }
      }

      .gridicon {
        display: none;
      }
    }

    .view {
      .listicon {
        display: none;
      }

      .gridicon {
        display: flex;
      }
    }
  }
}
.toggle-btn {
  padding: 0;
  margin: 0;
  display: block !important;
  background: unset;
  // vertical-align: middle;
  transition: 0.3s all ease-in-out;

  .switch {
    position: relative;
    display: inline-block;
    width: 50px !important;
    height: 25px;

    input {
      display: none;
    }

    .slider {
      border-radius: 34px;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 0.4s;
      background-color: #b7b7b7;

      &:before {
        content: '';
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        transition: 0.4s;
        border-radius: 50%;
        position: absolute;
        background-color: white;
        transform: translateX(0px);
      }

      .off,
      .on {
        top: 50%;
        left: 50%;
        font-size: 10px;
        color: white;
        transition: 0.4s;
        user-select: none;
        position: absolute;
        transform: translate(-50%, -50%);
        font-family: Verdana, sans-serif;
      }

      .on {
        display: none;
      }

      &.checked {
        background: linear-gradient(45deg, #ee7120, #ea5039);

        &:before {
          transform: translateX(24px);
        }

        .off {
          display: none;
        }

        .on {
          display: block;
        }
      }
    }
  }

  .title-tag {
    color: #333;
    font-size: 13px;
    vertical-align: top;
    margin: 2px 0px 9px 6px;
    display: inline-block;
  }
}
.sortfilter-box {
  width: 100%;
  z-index: 999;
  background: #ffffff;
  position: fixed;
  // bottom: -100%;
  padding: 0 0 10px 0;
  z-index: 4;
  left: 0px;
  right: 0px;
  bottom: 0px;

  .close-filter-sort {
    position: absolute;
    right: 5px;
    top: -35px;
    padding: 8px;

    img {
      filter: invert(1);
    }
  }

  .sort-head {
    color: #333333;
    font-size: 14px;
    font-family: 'pangrammedium';
    line-height: 16px;
    padding: 15px 18px;
    display: block;
    border-bottom: 1px solid #e7e7e7;
    margin: 0 0 5px 0;
    text-align: left;
  }

  .sorting-filter-list {
    .sorting-filter {
      padding: 10px 20px;

      label {
        color: #000000;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        font-family: 'pangramregular';
        display: block;
        width: 100%;
        text-align: left;
        padding: 0px;
        margin: 0px;

        span {
          right: 0px;
          left: auto;
        }
      }
    }
  }
}

.product-filter-bottom {
  display: flex;

  .product-filter-left {
    background: #f4f4f4;
    width: 40%;
    min-height: calc(100vh - 42px);
    padding-bottom: 210px;

    button {
      display: block;
      background: #f5f5f5;
      padding: 15px 12px;
      color: #333333;
      font-size: 0.875rem;
      line-height: 18px;
      text-transform: uppercase;
      border-right: 0px;
      position: relative;
      text-align: left;
      font-family: 'pangramregular';

      &.filter-active-tab {
        background-color: #fff;
      }
    }
  }

  .product-filter-right {
    width: 60%;
    padding-bottom: 210px;

    ul.rightfilter {
      margin: 0px;

      li {
        padding: 14px 14px 14px 25px;
        display: flex;
        justify-content: space-between;

        label {
          margin-bottom: 0px;
          width: 100%;

          span {
            width: 100%;
            white-space: wrap;
            word-break: break-all;
            padding-right: 35px;
            position: relative;
            overflow: revert;
            font-size: 0.8125rem;
            line-height: 18px;
            color: #242424;
            text-transform: capitalize;
            word-break: break-word;
            white-space: normal;
          }
        }

        filter {
          float: right;
          white-space: nowrap;
          position: absolute;
          right: 0px;
          top: 0px;
        }
        &.other-category {
          padding: 10px 14px 8px 25px;
          position: relative;
          span {
            color: #242424;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            font-size: 13px;
            font-family: 'pangramregular';
            line-height: 18px;
          }
          &:after {
            content: '';
            border-right: 1px solid #646464;
            border-bottom: 1px solid #646464;
            width: 7px;
            height: 7px;
            display: inline-block;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            position: absolute;
            right: 15px;
            top: 15px;
          }
        }
      }
    }
  }
}

.filter-footer-mob {
  position: inherit;
  bottom: 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;

  .filter-footer-btn {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .filterbtn {
    text-align: center;
    padding: 20px 55px;
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
  }

  .apply-btn {
    background: #e27a34;
    color: #ffffff;
    font-family: 'pangrammedium';
    letter-spacing: 1px;
    padding: 12px 50px;
    border-radius: 4px;
    width: auto;
  }
}

.selected-active {
  position: relative;

  &:after {
    content: 'L';
    color: #333333;
    display: inline-block;
    transform: scaleX(-1) rotate(-45deg);
    margin-left: 10px;
  }
}
